<template>
    <div>
        <div id="internal-server-error-page">
            <div id="status-code">
                500
            </div>
            <h1>Internal Server Error</h1>
            <div id="error-message">
                We're sorry, An error occurred. Go back to the homepage or contact support for further assistance.
            </div>
        </div>
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
#internal-server-error-page {
    max-width: 500px;
    margin: auto;
    padding-top: 300px;
}
#status-code {
  font-size: 100px;
}
</style>
